<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>上饶流动人才趋势分析</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="topchange">
                <el-input size="small" v-model="num2" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata">更改数据</el-button><el-button type="primary" size="small" @click="resetdata">重置数据</el-button>
            </div>
            <div class="list">
                <div class="listtitle"><strong>人才供需侧数量对</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd()">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list1"><span>岗位需求人数：{{item.jobDemand}}</span>----- <em>岗位投递人数：{{item.postDelivery}}</em><p><i @click="openedit(item)">编辑</i><i @click="deleteitem(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>重点产业流入流出对比</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd2()">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list2"><span>{{item.kiName}}</span><em>意向流入：{{item.inflow}}</em> <em>意向流出：{{item.outflow}}</em><p><i @click="openedit2(item)">编辑</i><i @click="deleteitem2(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>人才流入意向</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd3(1)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list3"><span>{{item.tsName}}</span><em>人才流入意向：{{item.tsValue}}</em><p><i @click="openedit3(item)">编辑</i><i @click="deleteitem3(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>人才流出意向</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd3(2)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list4"><span>{{item.tsName}}</span><em>人才流入意向：{{item.tsValue}}</em><p><i @click="openedit3(item)">编辑</i><i @click="deleteitem3(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
        </div>
        <pop-up title="添加" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="100px">
                    <el-form-item label="">
                        <span style="color:#f00;">最多可以添加12条</span>
                    </el-form-item>
                    <el-form-item label="岗位需求人数">
                        <el-input v-model="addfrom.jobDemand"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位投递人数">
                        <el-input v-model="addfrom.postDelivery"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="100px">
                    <el-form-item label="岗位需求人数">
                        <el-input v-model="editfrom.jobDemand"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位投递人数">
                        <el-input v-model="editfrom.postDelivery"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="添加" width="26%" :isshow="addshow2" :haveconfirm="true" @close="getclose3" @confirm="getconfirm3">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom2" label-width="100px">
                    <el-form-item label="名称">
                        <el-input v-model="addfrom2.kiName"></el-input>
                    </el-form-item>
                    <el-form-item label="意向流入">
                        <el-input v-model="addfrom2.inflow"></el-input>
                    </el-form-item>
                    <el-form-item label="意向流出">
                        <el-input v-model="addfrom2.outflow"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow2" :haveconfirm="true" @close="getclose4" @confirm="getconfirm4">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom2" label-width="100px">
                    <el-form-item label="名称">
                        <el-input v-model="editfrom2.kiName"></el-input>
                    </el-form-item>
                    <el-form-item label="意向流入">
                        <el-input v-model="editfrom2.inflow"></el-input>
                    </el-form-item>
                    <el-form-item label="意向流出">
                        <el-input v-model="editfrom2.outflow"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="添加" width="26%" :isshow="addshow3" :haveconfirm="true" @close="getclose5" @confirm="getconfirm5">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom3" label-width="100px">
                    <el-form-item label="城市">
                        <el-input v-model="addfrom3.tsName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="addfrom3.tsValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow3" :haveconfirm="true" @close="getclose6" @confirm="getconfirm6">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom3" label-width="100px">
                    <el-form-item label="城市">
                        <el-input v-model="editfrom3.tsName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="editfrom3.tsValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return{
            num:0,
            num2:"",
            list1:[],
            list2:[],
            list3:[],
            list4:[],
            addshow:false,
            addshow2:false,
            addshow3:false,
            editshow:false,
            editshow2:false,
            editshow3:false,
            addfrom:{
                jobDemand:"",
                postDelivery:"",
            },
            editfrom:{
                id:"",
                jobDemand:"",
                postDelivery:"",
            },
            addfrom2:{
                kiName:"",
                inflow:"",
                outflow:"",
            },
            editfrom2:{
                id:"",
                kiName:"",
                inflow:"",
                outflow:"",
            },
            addfrom3:{
                tsName:"",
                tsValue:"",
                type:1,
            },
            editfrom3:{
                id:"",
                tsName:"",
                tsValue:"",
                type:1,
            },
        }
    },
    created(){
        this.getList()
        this.getList2()
        this.getlist3()
        this.getlist4()
    },
    methods:{
        resetdata(){
            _api.get(serverurl.reset7,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList()
                    this.getList2()
                    this.getlist3()
                    this.getlist4()
                }
            })
        },
        changedata(){//更改数据
            if(this.num2==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random7,{num:this.num2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList()
                    this.getList2()
                    this.getlist3()
                    this.getlist4()
                }
            })
        },
        deleteitem3(row){
            _api.get(serverurl.talentdel,{id:row.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    if(row.type==1){
                        this.getlist3()
                    }else{
                        this.getlist4()
                    }
                }
            })
        },
        openedit3(row){
            this.editshow3=true
            this.editfrom3.id=row.id
            this.editfrom3.tsName=row.tsName
            this.editfrom3.tsValue=row.tsValue
            this.editfrom3.type=row.type
        },
        getconfirm6(){
             _api.post(serverurl.talentadd,this.editfrom3).then(res=>{
                this.editshow3=false
                if(res.success){
                    this.$message.success("编辑成功！")
                    if(this.editfrom3.type==1){
                        this.getlist3()
                    }else{
                        this.getlist4()
                    }
                }
            })
        },
        getclose6(){
            this.editshow3=false
        },
        getlist3(){
            _api.get(serverurl.talentlist,{type:1}).then(res=>{
                if(res.success){
                    this.list3=res.data
                }
            })
        },
        getlist4(){
            _api.get(serverurl.talentlist,{type:2}).then(res=>{
                if(res.success){
                    this.list4=res.data
                }
            })
        },
        getconfirm5(){
            _api.post(serverurl.talentadd,this.addfrom3).then(res=>{
                this.addshow3=false
                if(res.success){
                    this.$message.success("添加成功！")
                    if(this.addfrom3.type==1){
                        this.getlist3()
                    }else{
                        this.getlist4()
                    }
                    
                }
            })
        },
        getclose5(){
            this.addshow3=false
        },
        openadd3(e){
            this.addfrom3.type=e
            this.addshow3=true
        },
        deleteitem2(item){
            _api.get(serverurl.keydel,{id:item.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList2()
                }
            })
        },
        openedit2(row){
            this.editshow2=true
            this.editfrom2.id=row.id
            this.editfrom2.kiName=row.kiName
            this.editfrom2.inflow=row.inflow
            this.editfrom2.outflow=row.outflow
        },
        getconfirm4(){
            _api.post(serverurl.keyadd,this.editfrom2).then(res=>{
                this.editshow2=false
                if(res.success){
                    this.$message.success("编辑成功！")
                    this.getList2()
                }
            })
        },
        getclose4(){
            this.editshow2=false
        },
        getList2(){
            _api.get(serverurl.keylist,{}).then(res=>{
                if(res.success){
                    this.list2=res.data
                }
            })
        },
        getconfirm3(){
            _api.post(serverurl.keyadd,this.addfrom2).then(res=>{
                this.addshow2=false
                if(res.success){
                    this.$message.success("添加成功！")
                    this.getList2()
                }
            })
        },
        getclose3(){
            this.addshow2=false
        },
        openadd2(){
            this.addshow2=true
        },
        getList(){
            _api.get(serverurl.personlist,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.list1=res.data
                    this.num=this.list1.length
                }
            })
        },
        openadd(){
            this.addshow=true
        },
        openedit(row){
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.jobDemand=row.jobDemand
            this.editfrom.postDelivery=row.postDelivery
        },
        deleteitem(row){
            _api.get(serverurl.persondel,{id:row.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        getclose(){
            this.addshow=false
        },
        getconfirm(){
            if(this.num>=12){
                this.$message.error("最多只能添加12条！")
                return
            }else{
                _api.post(serverurl.personadd,this.addfrom).then(res=>{
                    if(res.success){
                        this.$message.success("添加成功！")
                        this.getList()
                    }
                })
            }
            
        },
        getclose2(){
            this.editshow=false
        },
        getconfirm2(){
            _api.post(serverurl.personadd,this.editfrom).then(res=>{
                this.editshow=false
                if(res.success){
                    this.$message.success("编辑成功！")
                    this.getList()
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/bigdata.css";
.topchange{ text-align: center; padding-bottom: 20px;}
</style>